import { useState, useEffect } from "react";
import axios from "axios";
import TableLogs from "./TableLogs";

const CheckLogsByRatio = () => {
  const [currentLog, setCurrentLog] = useState('Esports');
  const itemsPerPage = 7;

  const [date, setDate] = useState(new Date());
  const [percent, setPercent] = useState('');
  const [data, setData] = useState([]);
  const [rev, setrev] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const sortedData = rev.slice().sort((a, b) => new Date(b.dates) - new Date(a.dates));

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatedDate = new Date(date).toISOString().split("T")[0];

  const handleSelect = (event) => {
    setPercent(event.target.value);
  };

  const fetchData = async () => {
    const apiUrl = "/eapi/etotal";
    try {
      const response = await axios.post(apiUrl, {
        percent: percent,
      });
      localStorage.setItem('myData', percent);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetchrevenue = async () => {
    const apiUrl = "/eapi/etotalrevenue";
    try {
      const response = await axios.get(apiUrl);
      setrev(response.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [percent, currentLog]);

  useEffect(() => {
    fetchrevenue();
  }, []);

  return (
    <>
      {/* Your JSX code */}
      <div className=" w-full mx-auto ">
        {/* Navigation Bar */}
        <nav className="bg-purple-700 border-gray-200 dark:bg-gray-900">
          <div className="max-w-screen-xl flex flex-wrap items-center gap-4 justify-center mx-auto p-4">
            <h1 className="text-white text-3xl mx-auto  md:mx-0 font-bold md:text-4xl">ESPORT</h1>
          </div>
        </nav>
      </div>
      <div className="container flex flex-col px-5 gap-10 md:flex md:flex-row mx-auto justify-center mt-10">
        <div className="block max-w-sm p-6 bg-gray-100 border shadow-lg shadow-purple-500 border-gray-200 rounded-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          {/* Ratio Selector */}
          <div className="flex flex-col justify-center">
            <div className="mt-5">
              <select
                type='submit'
                id="small"
                size='5'
                className="block w-full p-2 py-2.5 mb-6 text-sm text-gray-500 font-bold border border-purple-500 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onChange={handleSelect}
                value={percent}
              >
                <option disabled>Select Ratio</option>
                {Array.from({ length: 18 }, (_, i) => (i + 1) * 5).map(value => (
                  <option key={value} value={value}>{value}</option>
                ))}
              </select>
            </div>
          </div>

          {/* Data Table or Placeholder */}
          <div className="m-2 p-2">
            {data?.length <= 0 ? (
              <h1 className="text-3xl font-bold font-mono bg-green-500 text-white p-2 text-center rounded">
                Nothing To show
              </h1>
            ) : (
              <TableLogs data={data} />
            )}
          </div>
        </div>

        {/* Table for Revenue Data */}
        <div className="relative overflow-x-auto  shadow-md sm:rounded-lg">
          <table className="w-full border  text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-100 uppercase bg-[#7E22CE] dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 text-center py-3">
                  DATE
                </th>
                <th scope="col" className="px-6 text-center py-3">
                  TOTAL SUB
                </th>
                <th scope="col" className="px-6 text-center py-3">
                  DAILY REVENUE
                </th>
                <th scope="col" className="px-6 text-center py-3">
                  UNSUB
                </th>
              </tr>
            </thead>
            <tbody>
            {currentItems
  .filter(item => Object.keys(item).length > 0) // Filter out empty objects
  .map((item, index) => (
    <tr
      key={index}
      className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700`}
    >
      <td className="px-6 text-center py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
        {item.date}
      </td>
      <td className="px-6 text-center py-4">{item.count ? item.count : '0'}</td>
      <td className="px-6 text-center py-4">{item.total_deducted_amount}</td>
      <td className="px-6 text-center py-4">{item.unsub_count || 0}</td>
    </tr>
))}

            </tbody>
          </table>
          {/* Pagination */}
          <nav className="flex flex-col  px-[90px] items-center justify-between pt-4" aria-label="Table navigation">
            <span className="text-sm font-normal text-center text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
              Showing <span className="font-semibold text-gray-900 dark:text-white">{indexOfFirstItem + 1}</span> to <span className="font-semibold text-gray-900 dark:text-white">{Math.min(indexOfLastItem, rev.length)}</span> of <span className="font-semibold text-gray-900 dark:text-white">{rev.length}</span> entries
            </span>
            <ul className="inline-flex -space-x-px py-1 rtl:space-x-reverse text-sm h-8">
              <li>
                <button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  Previous
                </button>
              </li>
              {/* Conditionally render page numbers */}
              {rev.length > 3 && (
                <>
                  {[currentPage, currentPage + 1, currentPage + 2].map((number) => (
                    <li key={number}>
                      <button
                        onClick={() => paginate(number)}
                        className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 ${currentPage === number ? 'bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700' : 'hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'}`}
                      >
                        {number}
                      </button>
                    </li>
                  ))}
                </>
              )}
              <li>
                <button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === Math.ceil(rev.length / itemsPerPage)}
                  className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default CheckLogsByRatio;
