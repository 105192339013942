import React, { useState } from "react";

import EsportLogs from './EsportLogs';
import Gamestation from './GamestationLogs';

const CheckLogs = () => {

  const [currentLog, setCurrentLog] = useState('Esports'); 

  return (
    <>
      <div className="w-full mx-auto">
        <nav className="bg-purple-700 border-gray-200 dark:bg-gray-900">
          <div className="max-w-screen-xl flex flex-wrap items-center gap-4 justify-center mx-auto p-4">
            <h1 className="text-white text-3xl  mx-auto md:mx-0 font-bold md:text-4xl">ESPORTS</h1>

            {/* <div className="mx-auto md:mx-0">
              <button
                type="button"
                className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-lg px-5 py-2.5 text-center me-2 mb-2"
                onClick={() => setCurrentLog('Gamestation')}
              >
                GAMESTATION
              </button>
              <button
                type="button"
                className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-lg px-5 py-2.5 text-center me-2 mb-2"
                onClick={() => setCurrentLog('EsportLogs')}
              >
                ESPORTS
              </button>
            </div> */}
          </div>
        </nav>
<EsportLogs/>
      
        {/* {currentLog === 'Gamestation' ? <Gamestation /> : <EsportLogs />} */}
      </div>
    </>
  );
};

export default CheckLogs;
